import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const FormHeading = ({ title, description, tooltip }) => {
	return (
		<Box sx={{ mb: 2 }}>
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<Typography
					variant="body1"
					component="div"
					sx={{ fontWeight: 600 }}
					mr={1}
				>
					{title}
				</Typography>
				{tooltip}
			</Box>

			<Typography
				variant="body2"
				component="div"
				sx={{ color: (theme) => theme.palette.grey }}
			>
				{description}
			</Typography>
		</Box>
	);
};

FormHeading.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	tooltip: PropTypes.node
};

export default FormHeading;
