import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import CampaignDescription from "./CampaignDescription";
import RankStatus from "./RankStatus";
import EngagementStatus from "./EngagementStatus";
import Status from "./Status";
import CampaignMenu from "./CampaignMenu";
import TotalCompetitors from "./TotalCompetitors";

const DashboardTableRow = (data) => {
	const [row, setRow] = useState(data);

	useEffect(() => {
		setRow(data);
	});

	return (
		<TableRow
			role="checkbox"
			tabIndex={-1}
			campaign-id={row._id}
			sx={{
				":last-child td": {
					borderBottom: "none"
				}
			}}
		>
			<TableCell>
				<CampaignDescription {...row} />
			</TableCell>
			<TableCell align="center" sx={{ textTransform: "lowercase" }}>
				{row.website.replace(
					/http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?/g,
					""
				)}
			</TableCell>
			<TableCell align="center">
				{!row.type ? "keyword" : row.type}
			</TableCell>
			<TableCell align="center" sx={{ textTransform: "lowercase" }}>
				{row.type?.toLowerCase() === "category" ? (
					<IconButton
						aria-label="launch"
						onClick={() => window.open(row.category_url, "_blank")}
					>
						<OpenInNewIcon />
					</IconButton>
				) : (
					row.keyword
				)}
			</TableCell>

			<TableCell align="center">
				<TotalCompetitors {...row} />
			</TableCell>
			<TableCell>
				<EngagementStatus {...row} />
			</TableCell>
			<TableCell>
				<RankStatus {...row} />
			</TableCell>

			<TableCell align="center">
				<Status {...row} />
			</TableCell>
			<TableCell align="center">
				<CampaignMenu {...row} />
			</TableCell>
		</TableRow>
	);
};

DashboardTableRow.propTypes = {
	data: PropTypes.object
};

export default DashboardTableRow;
