import { React, useEffect } from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";
import DashboardTableHead from "./DashboardTableHead";
import DashboardTableRow from "./DashboardTableRow";

import { useCampaigns } from "../../context/CampaignsContext";
import { Button, Link } from "@mui/material";
import { useUserAuth } from "../../context/UserAuthContext";
import { useAuth0 } from "@auth0/auth0-react";

const DashboardTable = () => {
	const { logout } = useAuth0();
	const { userLimitedAccess, clearUserAccessToken } = useUserAuth();

	const {
		campaignIsLoading,
		getCampaignList,
		campaign,
		campaignList,
		loadMoreCampaigns,
		campaignLimit
	} = useCampaigns();

	const handleLoadMore = () => {
		loadMoreCampaigns();
	};

	const handleLogout = () => {
		clearUserAccessToken();
		logout();
	};

	useEffect(() => {
		getCampaignList();
	}, []);

	return (
		<Box
			sx={{
				background:
					"repeating-linear-gradient(#F8F8F8,#F8F8F8 58px,#ffffff 0,#ffffff)"
			}}
		>
			<Container maxWidth="xl">
				{userLimitedAccess ? (
					<Box>
						Sorry, you do not have permission to access this
						content, please{" "}
						<Link onClick={handleLogout}>Logout</Link>
					</Box>
				) : (
					<>
						<Box sx={{ width: "100%" }}>
							<LinearProgress
								sx={{ opacity: !campaignIsLoading ? 0 : 100 }}
							/>
						</Box>
						<Paper
							sx={{
								width: "100%",
								overflow: "hidden",
								boxShadow: ` 0px 11px 22px rgb(0 0 0 / 5%)`
							}}
						>
							<TableContainer>
								<Table
									stickyHeader
									aria-label="sticky table"
									sx={{ textTransform: "capitalize" }}
								>
									<DashboardTableHead />
									<TableBody>
										{campaignList.map((campaign) => {
											return (
												<DashboardTableRow
													key={campaign._id}
													{...campaign}
												/>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								p: 3
							}}
						>
							{campaignLimit < campaign.num_results ? (
								<Button
									variant="text"
									sx={{ fontWeight: 800 }}
									size="large"
									color="secondary"
									onClick={handleLoadMore}
								>
									Load More
								</Button>
							) : null}
						</Box>
					</>
				)}
			</Container>
		</Box>
	);
};

export default DashboardTable;
