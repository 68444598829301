import * as React from "react";

import { TableHead as MuiTableHead } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { styled } from "@mui/system";

const columns = [
	{ id: "productName", label: "product", minWidth: 300 },
	{ id: "website", label: "website", align: "center" },
	{
		id: "campaignType",
		label: "c.type",
		align: "center"
	},
	{
		id: "campaignValue",
		label: "c.value",
		align: "center"
	},

	{ id: "Competitors", label: "Competitors", align: "center" },
	{ id: "engagementStatus", label: "engagement status" },
	{ id: "rankStatus", label: "rank status" },
	{ id: "status", label: "status", align: "center" }
];

const TableHead = styled(MuiTableHead)({
	th: {
		backgroundColor: "#ffffff"
	}
});

const DashboardTableHead = () => {
	return (
		<TableHead>
			<TableRow>
				{columns.map((column) => (
					<TableCell
						key={column.id}
						align={column.align}
						style={{ minWidth: column.minWidth }}
					>
						{column.label}
					</TableCell>
				))}
				<TableCell />
			</TableRow>
		</TableHead>
	);
};

export default DashboardTableHead;
