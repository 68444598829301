import {React, useState} from 'react';
import PropTypes from 'prop-types';

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const TotalCompetitors = (campaign) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);


    return (
        <div>
            <Typography
                aria-owns={open ? 'total-competitors' : undefined}
                aria-haspopup="true"
                onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
                onMouseLeave={() => setAnchorEl(null)}
                sx={{
                    textDecorationLine: 'underline',
                    textDecorationStyle: 'dotted',
                    textDecorationColor: 'gray',
                    textDecorationThickness: 2
       
                    
                }}
            >
                 {campaign.competitors.length}
            </Typography>
            <Popover
                id="total-competitors"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
               
                onClose={() => setAnchorEl(null)}
                disableRestoreFocus     
            >
                <Stack spacing={2} sx={{p:3}}>
                    {campaign.competitors.map((competitor, index) => {
                        return  <div key={index} >
                                    {`${index+1} : ${competitor.product_name}`}
                                </div>
                    })}
                </Stack>
                
            </Popover>
        
        </div>
    );
};

TotalCompetitors.propTypes = {
    campaign: PropTypes.object
};

export default TotalCompetitors;