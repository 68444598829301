import { React, createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { useUserAuth } from './UserAuthContext';
import { useGlobalAlert } from './GlobalAlertDialog';
import DownloadFile from '../utils/DownloadFile';

const CampaignsContext = createContext();

const campaignDefaultFormValue = {
	website: '',
	keyword: '',
	type: 'keyword',
	category_url: '',
	category_breadcrumbs: '',
	purchase_button: '',
	target_engagements: 0,
	target_rank: 0,
	daily_engagements: 0,
	competitors: [
		{
			product_name: '',
			product_url: '',
			backup: false,
		},
	],
	days_engagements: [],
	widgets: [],
	paused: false,
};

export function CampaignsContextProvider({ children }) {
	const { openDialog } = useGlobalAlert();
	const { userAccessToken, setUserLimitedAccess, clearUserAccessToken } =
		useUserAuth();
	const [campaignIsLoading, setCampaignIsLoading] = useState(false);
	const [campaign, setCampaign] = useState({});
	const [campaignList, setCampaignList] = useState([]);
	const [campaignFormValue, setCampaignFormValue] = useState(
		campaignDefaultFormValue
	);
	const [searchTerm, setSearchTerm] = useState(null);

	const campaignLimit = 20;

	const headerParam = {
		Accept: 'application/json',
		Authorization: `Bearer ${userAccessToken}`,
		'Content-Type': 'application/json',
	};

	const updateFormValue = (value) => {
		setCampaignFormValue(value);
	};

	const resetFormValue = () => {
		setCampaignFormValue(campaignDefaultFormValue);
	};

	const getCampaignList = () => {
		setCampaignIsLoading(true);

		fetch(
			`${process.env.REACT_APP_MRPUPPET_API}/campaigns?limit=${campaignLimit}`,
			{
				method: 'GET',
				headers: headerParam,
			}
		)
			.then((res) => {
				if (res.status >= 400) {
					throw res;
				}
				return res.json();
			})
			.then(
				(response) => {
					setUserLimitedAccess(false);
					setCampaign(response);
					setCampaignList(response.results);
					setCampaignIsLoading(false);
				},
				(err) => {
					if (err.status === 401 || err.status === 404) {
						clearUserAccessToken();
					}
					if (err.status === 403) {
						openDialog({
							title: `Limited Access`,
							description: `Sorry, you do not have permission to access this content.Please logout and contact the administrator if this is incorrect. `,
							button: 'Close',
						});
						setUserLimitedAccess(true);
						setCampaignIsLoading(false);
					}
				}
			);
	};

	const loadMoreCampaigns = () => {
		setCampaignIsLoading(true);

		const lastCampaignID = campaignList[campaignList.length - 1]._id;

		fetch(
			`${
				process.env.REACT_APP_MRPUPPET_API
			}/campaigns?limit=${campaignLimit}&next_cursor=${lastCampaignID}_created_at${
				searchTerm && `&query=${searchTerm}`
			}`,
			{
				method: 'GET',
				headers: headerParam,
			}
		)
			.then((resp) => resp.json())
			.then((data) => {
				let newCampaignList = campaignList;
				newCampaignList.push(...data.results);
				setCampaign(data);
				setCampaignList(newCampaignList);
				setCampaignIsLoading(false);
			})
			.catch((error) => console.log('error', error));
	};

	const searchCampaign = (searchTerm) => {
		setCampaignIsLoading(true);
		setSearchTerm(searchTerm);
		return fetch(
			`${process.env.REACT_APP_MRPUPPET_API}/campaigns?query=${searchTerm}`,
			{
				method: 'GET',
				headers: headerParam,
			}
		)
			.then((resp) => resp.json())
			.then((data) => {
				if (data.results.length !== 0) {
					setCampaign(data);
					setCampaignList(data.results);
				}

				setCampaignIsLoading(false);

				return data.results;
			})
			.catch((error) => console.log('error', error));
	};

	const getCampaign = (id) => {
		setCampaignIsLoading(true);

		fetch(`${process.env.REACT_APP_MRPUPPET_API}/campaign/${id}`, {
			method: 'GET',
			headers: headerParam,
		})
			.then((resp) => resp.json())
			.then(() => {
				setCampaignIsLoading(false);
			})
			.catch((error) => console.log('error', error));
	};

	const createCampaign = (campaignValues) => {
		setCampaignIsLoading(true);

		campaignValues = {
			...campaignValues,
			competitors:
				campaignValues.competitors[0].product_name === ''
					? []
					: campaignValues.competitors,
		};

		return fetch(`${process.env.REACT_APP_MRPUPPET_API}/campaign`, {
			method: 'POST',
			headers: headerParam,
			body: JSON.stringify(campaignValues),
		})
			.then((resp) => resp.json())
			.then((data) => {
				let newCampaignList = campaignList;
				newCampaignList.unshift(data);
				setCampaignList(newCampaignList);
				setCampaignIsLoading(false);
				resetFormValue();
				return data;
			})
			.catch((error) => console.log('error', error));
	};

	const updateCampaign = (campaignValues) => {
		setCampaignIsLoading(true);

		campaignValues = {
			...campaignValues,
			competitors:
				campaignValues.competitors[0]?.product_name === ''
					? []
					: campaignValues.competitors,
			first_product_category_rank:
				campaignValues.first_product_category_rank ?? 0,
			first_product_search_rank:
				campaignValues.first_product_search_rank ?? 0,
		};

		return fetch(
			`${process.env.REACT_APP_MRPUPPET_API}/campaign/${campaignValues._id}`,
			{
				method: 'PUT',
				headers: headerParam,
				body: JSON.stringify(campaignValues),
			}
		)
			.then((res) => {
				if (res.ok) {
					return res.json();
				}
				return Promise.reject(res);
			})
			.then((data) => {
				let newCampaignList = campaignList;
				let foundIndex = newCampaignList.findIndex(
					(x) => x._id == data._id
				);
				newCampaignList[foundIndex] = data;
				setCampaignList(newCampaignList);

				setCampaignIsLoading(false);
				resetFormValue();
				return data;
			})
			.catch((error) => {
				setCampaignIsLoading(false);

				return error;
			});
	};

	const getCampaignReport = (campaign) => {
		setCampaignIsLoading(true);

		fetch(
			`${process.env.REACT_APP_MRPUPPET_API}/campaign/${campaign._id}/report`,
			{
				method: 'GET',
				headers: { ...headerParam, Accept: 'text/csv' },
			}
		)
			.then((resp) => resp.text())
			.then((data) => {
				DownloadFile(`mrpuppet_campaign_${campaign._id}`, data);
				setCampaignIsLoading(false);
			})
			.catch((error) => console.log('error', error));
	};

	return (
		<CampaignsContext.Provider
			value={{
				campaignDefaultFormValue,
				campaignFormValue,
				updateFormValue,
				resetFormValue,
				campaignIsLoading,
				campaign,
				campaignList,
				getCampaignList,
				getCampaign,
				createCampaign,
				updateCampaign,
				setCampaign,
				getCampaignReport,
				searchCampaign,
				loadMoreCampaigns,
				campaignLimit,
			}}
		>
			{children}
		</CampaignsContext.Provider>
	);
}

CampaignsContextProvider.propTypes = {
	children: PropTypes.node,
};

export function useCampaigns() {
	return useContext(CampaignsContext);
}
