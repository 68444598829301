import React from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import FormHeading from "./FormHeading";
import FormSection from "./FormSection";

import { WEBSITES, PURCHASE_BUTTON, TYPE } from "../../enums/campaign";
import { Information } from "../Tooltip";
import { Grid } from "@mui/material";
import { useCampaignsForm } from "../../context/CampaignsFormContext";

const CampaignDetail = () => {
	const { watch, register } = useCampaignsForm();

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={6}>
				<FormSection>
					<FormHeading
						title="Website"
						tooltip={
							<Information text="Which website would you like Mr. Puppet to engage with your product on?" />
						}
					/>

					<FormControl fullWidth>
						<InputLabel id="product-website">
							Select Website
						</InputLabel>

						<Select
							{...register("website", {
								required: "Please select a website"
							})}
							value={watch("website")}
							labelId="product-website-label"
							id="product-website-select"
							label="Select Website"
						>
							<MenuItem value={""}>-------</MenuItem>
							{Object.values(WEBSITES).map(function (
								website,
								idx
							) {
								return (
									<MenuItem key={idx} value={website}>
										{website.replace(
											/http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?/g,
											""
										)}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</FormSection>

				<FormSection>
					<FormHeading
						title="Select Campaign Type"
						tooltip={
							<Information text="What type of campaign are you trying to create?" />
						}
					/>

					<FormControl fullWidth>
						<InputLabel id="compaign-type">Select Type</InputLabel>
						<Select
							{...register("type", {
								required: "Please select a type"
							})}
							value={watch("type")?.toLocaleLowerCase()}
							labelId="compaign-type-label"
							id="compaign-type-select"
							name="type"
							label="Select Type"
						>
							<MenuItem value={""}>-------</MenuItem>
							{Object.values(TYPE).map(function (type, idx) {
								return (
									<MenuItem
										key={idx}
										value={type.toLocaleLowerCase()}
									>
										{type}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</FormSection>
				{watch("type")?.toLocaleLowerCase() ===
				TYPE.KEYWORD_RANK.toLocaleLowerCase() ? (
					<FormSection>
						<FormHeading
							title="Enter a Keyword to be searched"
							tooltip={
								<Information
									text="Which keyword would you like Mr.Puppet to help your product rank better for?
							"
								/>
							}
						/>
						<TextField
							{...register("keyword", {
								required:
									watch("type") === TYPE.KEYWORD_RANK
										? "Keyword is required"
										: false
							})}
							label="Keyword"
							name="keyword"
							fullWidth
						/>
					</FormSection>
				) : null}
			</Grid>
			<Grid item xs={12} md={6}>
				<FormSection>
					<FormHeading
						title="Define Purchase Button"
						tooltip={
							<Information text="Which button would you like Mr. Puppet to Engage with?" />
						}
					/>
					<FormControl fullWidth>
						<InputLabel id="purchase-button-label">
							Select Button Type
						</InputLabel>
						<Select
							{...register("purchase_button")}
							value={watch("purchase_button") || ""}
							labelId="purchase-button-label"
							id="purchase-button"
							name="purchase_button"
							label="Select Button Type"
						>
							<MenuItem value="">-------</MenuItem>
							{Object.values(PURCHASE_BUTTON).map(
								(buttonValue) => {
									return (
										<MenuItem
											key={buttonValue}
											value={buttonValue.toLocaleLowerCase()}
										>
											{buttonValue}
										</MenuItem>
									);
								}
							)}
						</Select>
					</FormControl>
				</FormSection>
				<FormSection>
					<FormHeading
						title="Enter URL of the keyword’s 'Category Page'"
						tooltip={
							<Information
								text="This will be used to track Mr Puppet’s impact on the product’s ranking on the Category page.
							For example, if your keyword is ‘vitamin C’, on Target’s website the category page would be: https://www.target.com/c/vitamin-c/-/N-4l6j5"
							/>
						}
					/>
					<FormControl fullWidth>
						<TextField
							{...register("category_url", {
								required: "Please select an option"
							})}
							label="Category Url"
							name="category_url"
							placeholder="https://www.target.com/c/vitamin-c/-/N-4l6j5"
						/>
					</FormControl>
				</FormSection>
			</Grid>
		</Grid>
	);
};

export default CampaignDetail;
