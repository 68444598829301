import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { styled } from "@mui/system";
import sumWithAccount from "../../utils/SumEngagmentsWithAccount";

const EngagementStatusContainer = styled(Stack)({
	backgroundColor: `#F8F8F8`,
	padding: `10px 0`,
	borderRadius: 4
});

const BoxContainer = styled(Box)({
	padding: `0 10px`
});

const RankValue = styled(Typography, {
	shouldForwardProp: (prop) => prop !== "statusColor"
})(({ theme, statusColor }) => ({
	fontWeight: 800,
	color:
		statusColor !== undefined
			? statusColor === "red"
				? theme.palette.error.main
				: theme.palette.success.main
			: ""
}));

const RankLabel = styled(Typography)({
	color: "#909090",
	fontWeight: 600,
	fontSize: 12
});

const EngagementStatus = (campaign) => {
	return (
		<EngagementStatusContainer direction="row" spacing={2}>
			<BoxContainer>
				<RankValue
					variant="body2"
					component="div"
					statusColor={undefined}
				>
					{campaign.target_engagements}
				</RankValue>
				<RankLabel variant="caption text" component="div">
					Total
				</RankLabel>
			</BoxContainer>

			<BoxContainer>
				<RankValue
					variant="body2"
					component="div"
					statusColor={undefined}
				>
					{campaign.engagements
						? sumWithAccount(campaign.engagements)
						: "N/A"}
				</RankValue>
				<RankLabel variant="caption text" component="div">
					Completed
				</RankLabel>
			</BoxContainer>
		</EngagementStatusContainer>
	);
};

EngagementStatus.propTypes = {
	campaign: PropTypes.object
};

export default EngagementStatus;
