import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const CampaignDescription = campaign => {
	const campaignLastRun = useMemo(() => {
		return DateTime.fromISO(campaign.last_run).toLocaleString(
			DateTime.DATETIME_MED
		);
	}, [campaign.last_run]);

	return (
		<Card
			campaign-id={campaign._id}
			sx={{
				display: 'flex',
				boxShadow: 0,
				backgroundColor: 'transparent',
			}}
		>
			{!campaign.last_run ? (
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<CircularProgress />
				</Box>
			) : (
				<CardMedia
					component="img"
					sx={{ objectFit: 'contain', width: 50 }}
					image={
						campaign.image_url !== undefined
							? campaign.image_url
							: process.env.PUBLIC_URL + '/no-image.jpeg'
					}
					alt={campaign.product_name}
				/>
			)}

			<CardContent>
				<Typography
					component="div"
					variant="subtitle2"
					sx={{
						fontWeight: 600,
						fontStyle:
							campaign.product_name === '' ? 'italic' : 'inherit',
					}}
				>
					{campaign?.product_name === ''
						? 'Currently Scarping Data...'
						: campaign.product_name.length > 50
						? campaign.product_name.substring(0, 100) + '...'
						: campaign.product_name}
				</Typography>
				{campaign.last_run ? (
					<Typography
						variant="subtitle2"
						color="text.secondary"
						component="div"
					>
						Last update: {campaignLastRun}
					</Typography>
				) : null}
			</CardContent>
		</Card>
	);
};

CampaignDescription.propTypes = {
	campaign: PropTypes.object,
};

export default CampaignDescription;
