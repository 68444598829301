import React, { useEffect } from 'react';

import { useFieldArray } from 'react-hook-form';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useCampaignsForm } from '../../context/CampaignsFormContext';

const defaultValues = {
	product_name: '',
	product_url: '',
	backup: false,
};

const FormDynamicCompetitors = () => {
	const {
		formState: { errors },
		register,
		setValue,
		control,
	} = useCampaignsForm();
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'competitors',
	});

	const handleCheckboxChange = (index) => (e) => {
		const { checked } = e.target;
		setValue(`items[${index}].backup`, checked);
	};

	useEffect(() => {
		if (fields.length === 0) {
			append(defaultValues);
		}
	}, [append, fields.length]);

	return (
		<>
			{fields.map((field, index) => {
				return (
					<Stack
						sx={{
							border: '1px solid #adadad',
							padding: `16px 24px`,
							borderRadius: 1,
							mb: 2,
						}}
						spacing={2}
						key={field.id}
					>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
						>
							<Typography sx={{ fontWeight: 600 }}>
								{`Competitor ${index + 1}`}
							</Typography>
							<Box>
								<IconButton
									disabled={fields.length <= 1}
									onClick={() => remove(index)}
								>
									<RemoveCircleIcon
										size="small"
										color={
											fields.length <= 1
												? 'disabled'
												: 'warning'
										}
									/>
								</IconButton>

								<IconButton
									onClick={() => append(defaultValues)}
								>
									<AddCircleIcon
										size="small"
										color="success"
									/>
								</IconButton>
							</Box>
						</Stack>
						<TextField
							{...register(`competitors[${index}].product_name`, {
								// required: "Please provide a name"
							})}
							defaultValue={field.product_name}
							label="Competitor Name"
							size="small"
							fullWidth
							error={!!errors.product_name}
							helperText={errors.product_name?.message}
						/>
						<TextField
							{...register(`competitors[${index}].product_url`, {
								// required: "Please provide a url"
							})}
							defaultValue={field.product_url}
							label="Product Url"
							placeholder=" https://www.target.com/c/letter-vitamins-supplements-health/-/N-4spfi"
							size="small"
							fullWidth
							error={!!errors.product_url}
							helperText={errors.product_url?.message}
						/>
						<FormGroup>
							<FormControlLabel
								sx={{
									justifyContent: 'flex-end',
									mr: 0,
									'.MuiFormControlLabel-label': {
										fontSize: 13,
									},
								}}
								name="backup"
								control={
									<Checkbox
										{...register(
											`competitors[${index}].backup`
										)}
										defaultChecked={field.backup}
										sx={{
											'& .MuiSvgIcon-root': {
												fontSize: 15,
											},
										}}
										onChange={handleCheckboxChange}
									/>
								}
								label="Use as backup product"
							/>
						</FormGroup>
					</Stack>
				);
			})}
		</>
	);
};

export default FormDynamicCompetitors;
