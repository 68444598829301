import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

const FormSection = ({ children }) => {
	return (
		<Box
			sx={{
				marginBottom: 4,
				// paddingBottom: 4,
				// borderBottom: `1px solid #e0e0e0`,
				":last-child": {
					borderBottom: 0,
					marginBottom: 0,
					paddingBottom: 0
				}
			}}
		>
			{children}
		</Box>
	);
};

FormSection.propTypes = {
	children: PropTypes.node
};

export default FormSection;
