export const WEBSITES = {
	I_HERB: "www.iherb.com"
};

export const PURCHASE_BUTTON = {
	SHIP_IT: "ship it",
	SHIP: "ship",
	ADD_TO_CART: "add to cart"
};

export const WIDGETS = {
	FREQUENTLY_BOUGHT_TOGETHER: "frequently bought together",
	BESTSELLERS_IN_CATEGORY: "bestsellers in category",
	FEATURED_PRODUCTS: "featured products"
};

export const TYPE = {
	KEYWORD_RANK: "Keyword",
	CATERGORY_PAGE: "Category"
};

export const CAMPAIGN_STEPS = {
	DETAILS: "Campaign Detail",
	VIVA: "Viva Natural's Detail",
	COMPETITORS: "Competitor's Detail",
	ENGAGEMENT: "Engagement Setting"
};
