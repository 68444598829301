import { React } from 'react';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';


import PageTitle  from '../components/PageTitle'
import Searchbar from '../components/Searchbar'
import CampaignFormDialog from '../components/CampaignFormDialog'
import Navbar from '../components/Navbar';
import DashboardTable from '../components/DashboardTable/DashboardTable'

import { useCampaigns } from '../context/CampaignsContext';

const DashboardPage = () => {
    const { searchCampaign } = useCampaigns();
	return ( 
        <>
            <CampaignFormDialog />
            <Navbar />
            <Box sx={{backgroundColor: "#F8F8F8"}} as="main" >
                <Container maxWidth="xl"> 
                    <PageTitle title="Mr.Puppet" subtitle="Automate engagement to increase your search and category ranking."/>
                    <Searchbar sx={{mb:5}} handleOnClick={searchCampaign}/>
                </Container> 
                <DashboardTable/>
            </Box>
        </>
	);
}


export default DashboardPage;
