import {React,useEffect} from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { useUserAuth } from "../context/UserAuthContext";
import { useAuth0 } from "@auth0/auth0-react";

import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import LocalStorage from '../utils/LocalStorage';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://vnsuite.com/">
        VNsuite
      </Link>{' '}
	{new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const LoginPage = () => {
  const {  isAuthenticated, getAccessTokenWithPopup} = useAuth0();
  const {  	getUserAccessToken, 
			userAuthenticated, 
			userAuthIsLoading, 
			setUserAuthIsLoading, 
			setUserAuthenticated, 
			setUserAccessToken,
			clearUserAccessToken
		} = useUserAuth();
  const storedUserAccessToken = LocalStorage.getItem('mrpuppet_token'); 
  const navigate = useNavigate()
  


	const handleSubmit = (event) => {
		event.preventDefault();
		getAccessTokenWithPopup().then((token) => {
			getUserAccessToken(token)	
			navigate('/dashboard')
		})
	};

	
	useEffect(() => {
		const auth0IsAuthenticated = isAuthenticated

		if (auth0IsAuthenticated && userAuthenticated) {
			navigate('/dashboard')
		} else if (!auth0IsAuthenticated && userAuthenticated) {
			clearUserAccessToken()
		} else if (auth0IsAuthenticated && !userAuthenticated && storedUserAccessToken !== null) {
			setUserAuthIsLoading(true)
			setUserAccessToken(storedUserAccessToken);
			setUserAuthenticated(true)
        }
		
	
	}, [userAuthenticated, isAuthenticated])
	
	return (
		<Box sx={{backgroundColor: '#F8F8F8', height: '100vh', display:'flex', alignItems:'center'}}>
			<Container maxWidth="xs" >
					<Box
						sx={{
							
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							backgroundColor: '#ffffff',
							p: 3,
							borderRadius: 1,
							boxShadow:` 0px 11px 22px rgb(0 0 0 / 5%)`,
						}}
					>
					
						<Box src={process.env.PUBLIC_URL + '/mrpuppet-logo.png'} component="img" sx={{width: 50, mb:2}}/>
						<Typography component="h1" variant="h5" sx={{mb:3}}>
							Welcome to <strong>Mr.Puppet</strong>
						</Typography>
						<Button variant="contained" size="large" onClick={handleSubmit} disabled={userAuthIsLoading}> {userAuthIsLoading ? "loading" : "Login"} </Button>
					</Box>
					<Copyright sx={{ mt: 3, mb: 4 }} />
			</Container>
		</Box>
	);
}

export default LoginPage;