import {React, useEffect} from 'react';
import { Outlet, Navigate } from 'react-router-dom'

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { useAuth0 } from "@auth0/auth0-react";
import { useUserAuth } from "../context/UserAuthContext";
import LocalStorage from './LocalStorage';

const ProtectedRoutes = () => {
    const { isAuthenticated, isLoading} = useAuth0();
    const { userAuthenticated, setUserAuthenticated, setUserAccessToken} = useUserAuth();

    useEffect(() => {
		
        if (!userAuthenticated) {
            const storedUserAccessToken = LocalStorage.getItem('mrpuppet_token'); 
            if (storedUserAccessToken) {
                setUserAccessToken(storedUserAccessToken);
                setUserAuthenticated(true)
            }
        }
		
	}, [userAuthenticated, isAuthenticated])

    if (isLoading) {
        return  <Box sx={{ 
                        display: 'flex', 
                        height: '100vh', 
                        width: '100wv', 
                        alignItems:"center", 
                        justifyContent:"center"
                    }}>
                    <CircularProgress size={65}/>
                </Box>
    }
    
    if (!userAuthenticated) {
        return <Navigate to="/login"/>;
    }

    return <Outlet/>;
}

export default ProtectedRoutes