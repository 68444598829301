import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { Auth0Provider } from "@auth0/auth0-react";

import ProtectedRoutes from "./utils/ProtectedRoutes";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import { DialogFormContextProvider } from "./context/DialogFormContext";
import { GlobalAlertDialogProvider } from "./context/GlobalAlertDialog";
import { CampaignsContextProvider } from "./context/CampaignsContext";
import { CampaignsFormContextProvider } from "./context/CampaignsFormContext";

import { Dashboard, Login, Logout } from "./pages";

import "./App.css";

const font = "'Montserrat', sans-serif";

let theme = createTheme({
	typography: {
		fontFamily: font,
		color: "#383838"
	},
	palette: {
		secondary: {
			main: "#494949"
		},
		primary: {
			main: "#6096B5"
		},
		error: {
			main: "#EB7644"
		},
		success: {
			main: "#94B469"
		}
	},
	button: {
		boxShadow: ` 0px 11px 22px rgb(0 0 0 / 5%)`
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 100
				}
			}
		}
	}
});

const App = () => {
	return (
		<Auth0Provider
			domain={process.env.REACT_APP_AUTH0_DOMAIN}
			clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
			authorizationParams={{
				redirect_uri: window.location.origin
			}}
		>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<UserAuthContextProvider>
					<DialogFormContextProvider>
						<GlobalAlertDialogProvider>
							<CampaignsContextProvider>
								<CampaignsFormContextProvider>
									<Router>
										<Routes>
											<Route
												element={<ProtectedRoutes />}
											>
												<Route
													element={<Dashboard />}
													path="/dashboard"
												/>
											</Route>

											<Route
												element={<Logout />}
												path="/logout"
											/>
											<Route
												element={<Login />}
												path="/login"
											/>
											<Route
												element={<Login />}
												path="*"
											/>
										</Routes>
									</Router>
								</CampaignsFormContextProvider>
							</CampaignsContextProvider>
						</GlobalAlertDialogProvider>
					</DialogFormContextProvider>
				</UserAuthContextProvider>
			</ThemeProvider>
		</Auth0Provider>
	);
};

export default App;
