import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/system';

const RankStatusContainer = styled(Stack)({
	backgroundColor: `#F8F8F8`,
	padding: `10px 0`,
	borderRadius: 4,
});

const BoxContainer = styled(Box)({
	padding: `0 10px`,
});

const RankValue = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'statusColor',
})(({ theme, statusColor }) => ({
	fontWeight: 800,
	color:
		statusColor !== undefined
			? statusColor === 'red'
				? theme.palette.error.main
				: theme.palette.success.main
			: '',
}));

const RankLabel = styled(Typography)({
	color: '#909090',
	fontWeight: 600,
	fontSize: 12,
});

const RankStatus = (campaign) => {
	const originalRank = useMemo(() => {
		if (
			campaign.first_product_search_rank === 0 &&
			campaign.first_product_category_rank === 0
		) {
			return 'N/A';
		} else if (campaign.type?.toLowerCase() === 'category') {
			return campaign.first_product_category_rank;
		} else if (campaign.type?.toLowerCase() === 'keyword') {
			return campaign.first_product_search_rank;
		} else {
			return campaign.first_product_search_rank;
		}
	}, [campaign.type]);

	const currentRank = useMemo(() => {
		if (
			(campaign.last_product_search_rank === 0 ||
				campaign.last_product_search_rank === null) &&
			(campaign.last_product_category_rank === 0 ||
				campaign.last_product_category_rank === null)
		) {
			return 'N/A';
		} else if (campaign.type?.toLowerCase() === 'category') {
			return campaign.last_product_category_rank;
		} else if (campaign.type?.toLowerCase() === 'keyword') {
			return campaign.last_product_search_rank;
		} else {
			return campaign.last_product_search_rank;
		}
	}, [campaign.type]);

	return (
		<RankStatusContainer direction="row" spacing={2}>
			<BoxContainer>
				<RankValue
					variant="body2"
					component="div"
					statusColor={undefined}
				>
					{campaign.target_rank}
				</RankValue>
				<RankLabel variant="caption text" component="div">
					Target
				</RankLabel>
			</BoxContainer>
			<BoxContainer>
				<RankValue variant="body2" component="div">
					{originalRank}
				</RankValue>
				<RankLabel variant="caption text" component="div">
					Original
				</RankLabel>
			</BoxContainer>
			<BoxContainer>
				<RankValue variant="body2" component="div">
					{currentRank}
				</RankValue>
				<RankLabel variant="caption text" component="div">
					Current
				</RankLabel>
			</BoxContainer>
		</RankStatusContainer>
	);
};

RankStatus.propTypes = {
	campaign: PropTypes.object,
};

export default RankStatus;
