import { React,createContext, useContext, useState } from "react";
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const GlobalAlertDialog = createContext();
const defaultValue = {
    title: 'test',
    description: 'test',
    button: 'button1'
}

export function GlobalAlertDialogProvider({ children }) {
    const [alert, setAlert ] = useState(defaultValue)
    const [open, setOpen] = useState(false)

    const openDialog = (message) => {
        setAlert(message)
        setOpen(true)
    }

    const closeDialog = () => {
        setOpen(false)
    }

  return (
    <GlobalAlertDialog.Provider
      value={{ open, openDialog, closeDialog, alert, setAlert}}
    >
        <Dialog
            open={open}
            
            fullWidth={true}
            aria-labelledby="global-alert-title"
            aria-describedby="global-alert-description"
        >
            <DialogTitle id="global-alert-title">
                {alert.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="global-alert-description">
                    {alert.description}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{p:2}}>
                <Button variant="contained" size="large" onClick={closeDialog} autoFocus>
                    {alert.button}
                </Button>
            </DialogActions>
        </Dialog>
        {children}
    </GlobalAlertDialog.Provider>
  );
}


GlobalAlertDialogProvider.propTypes = {
    children: PropTypes.node
};


export function useGlobalAlert() {
  return useContext(GlobalAlertDialog);
}