import { React, useState } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVert from '@mui/icons-material/MoreVert';

import { useFormDialog } from '../../context/DialogFormContext';
import { useCampaigns } from '../../context/CampaignsContext';

const CampaignMenu = (campaign) => {
	const [menuAnchor, setMenuAnchor] = useState(null);
	const { getCampaignReport, updateCampaign } = useCampaigns();
	const { openDialog } = useFormDialog();
	const openUserMenu = Boolean(menuAnchor);

	const handlePauseCampaign = () => {
		const updatedPauseStatus = {
			...campaign,
			paused: campaign.paused ? false : true,
		};
		updateCampaign(updatedPauseStatus);
	};

	return (
		<>
			<IconButton
				color="secondary"
				id="menu-button"
				aria-controls={openUserMenu ? 'menu' : undefined}
				aria-haspopup="true"
				aria-expanded={openUserMenu ? 'true' : undefined}
				onClick={(e) => setMenuAnchor(e.currentTarget)}
			>
				<MoreVert />
			</IconButton>
			<Menu
				id="menu"
				anchorEl={menuAnchor}
				open={openUserMenu}
				onClose={() => setMenuAnchor(null)}
				MenuListProps={{
					'aria-labelledby': 'menu',
				}}
			>
				<MenuItem
					onClick={() => {
						openDialog('edit', campaign), setMenuAnchor(null);
					}}
				>
					Edit
				</MenuItem>
				<MenuItem
					onClick={() => {
						handlePauseCampaign(), setMenuAnchor(null);
					}}
				>
					{campaign.paused ? 'Run Campaign' : 'Pause Campaign'}
				</MenuItem>
				<MenuItem onClick={getCampaignReport.bind(this, campaign)}>
					Download Report
				</MenuItem>
			</Menu>
		</>
	);
};

CampaignMenu.propTypes = {
	data: PropTypes.object,
};

export default CampaignMenu;
