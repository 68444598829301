import { React, useState } from "react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";

import { useAuth0 } from "@auth0/auth0-react";
import { useFormDialog } from "../context/DialogFormContext";

import { styled } from "@mui/system";
import { useUserAuth } from "../context/UserAuthContext";

const NavbarContainer = styled(Box)({
	backgroundColor: "#F8F8F8"
});

const MainAppBar = styled(AppBar)({
	backgroundColor: "transparent",
	marginTop: 0,
	boxShadow: "none",
	".MuiToolbar-root": {
		padding: 0
	}
});

const SubAppBar = styled(Stack)({
	color: "#504642",
	fontWeight: 800,
	borderBottom: "1px solid #A5A5A5",
	padding: "10px 0 ",
	marginBottom: 15
});

const Navbar = () => {
	const { logout, user } = useAuth0();
	const { clearUserAccessToken, userLimitedAccess } = useUserAuth();
	const { openDialog } = useFormDialog();
	const [userMenuAnchor, setUserMenuAnchor] = useState(null);
	const openUserMenu = Boolean(userMenuAnchor);

	const handleLogout = () => {
		clearUserAccessToken();
		logout();
	};

	return (
		<NavbarContainer>
			<Container maxWidth="xl">
				<SubAppBar
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Typography sx={{ fontWeight: 600 }}>
						<Link
							color="inherit"
							sx={{ textDecoration: "none" }}
							href="https://vnsuite.com/"
						>
							<Box sx={{ fontWeight: 800 }} component={"span"}>
								VN
							</Box>
							Suite
						</Link>
					</Typography>
					<IconButton
						color="primary"
						id="user-menu-button"
						aria-controls={openUserMenu ? "user-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={openUserMenu ? "true" : undefined}
						onClick={(e) => setUserMenuAnchor(e.currentTarget)}
					>
						<Avatar
							src={user.picture}
							alt={`${user.given_name} ${user.family_name}`}
							style={{
								width: "40px",
								height: "40px"
							}}
						/>
					</IconButton>
					<Menu
						id="user-menu"
						anchorEl={userMenuAnchor}
						open={openUserMenu}
						onClose={() => setUserMenuAnchor(null)}
						MenuListProps={{
							"aria-labelledby": "user-menu"
						}}
					>
						<Box
							sx={{
								minWidth: 250,
								p: 2,
								display: "flex",
								alignItems: "center",

								borderBottom: "1px solid #A5A5A5",
								fontWeight: 600,
								fontSize: 18
							}}
						>
							<Avatar
								src={user.picture}
								alt={`${user.given_name} ${user.family_name}`}
								style={{
									width: "50px",
									height: "50px"
								}}
								sx={{ mr: 2 }}
							/>

							<Box
								sx={{
									display: "flex",

									flexDirection: "column"
								}}
							>
								<Typography
									variant="body1"
									sx={{ fontWeight: 700 }}
								>
									{user.given_name} {user.family_name}
								</Typography>
								<Typography variant="body2">
									{user.email}
								</Typography>
							</Box>
						</Box>
						<MenuItem onClick={handleLogout}>Logout</MenuItem>
					</Menu>
				</SubAppBar>
				<MainAppBar position="static" elavation={0} enableColorOnDark>
					<Toolbar>
						<Box
							src={process.env.PUBLIC_URL + "/mrpuppet-logo.png"}
							component="img"
							sx={{ width: 30, mr: 1 }}
						/>
						<Typography
							variant="h6"
							component="div"
							sx={{
								flexGrow: 1,
								color: "#504642",
								fontWeight: 800
							}}
						>
							Mr.Puppet
						</Typography>
						<Button
							variant="contained"
							color="secondary"
							size="large"
							disabled={userLimitedAccess}
							onClick={() => openDialog()}
						>
							Create Campaign
						</Button>
					</Toolbar>
				</MainAppBar>
			</Container>
		</NavbarContainer>
	);
};

export default Navbar;
