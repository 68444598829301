/* eslint-disable */
import { React, useState, useEffect, useContext } from 'react';

import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { styled } from '@mui/system';

import { useFormDialog } from '../context/DialogFormContext';
import { useCampaigns } from '../context/CampaignsContext';
import { useCampaignsForm } from '../context/CampaignsFormContext';
import { useGlobalAlert } from '../context/GlobalAlertDialog';

import CampaignDetail from './CampignForm/CampaignDetail';
import VivaDetail from './CampignForm/VivaDetail';
import CompetitorsDetail from './CampignForm/CompetitorsDetail';
import EngagementsSetting from './CampignForm/EngagementsSetting';

import { CAMPAIGN_STEPS } from '../enums/campaign';

const StepperForm = styled(Container)({
	paddingTop: 40,
});

const StepperTitle = styled(Typography)({
	backgroundColor: 'transparent',
	marginTop: 0,
	boxShadow: 'none',
});

const Steps = Object.values(CAMPAIGN_STEPS).map((step) => step);

const CampaignFormDialog = () => {
	const { open, closeDialog, isEdit, formData } = useFormDialog();
	const { openDialog } = useGlobalAlert();
	const [activeStep, setActiveStep] = useState(0);

	const { createCampaign, updateCampaign, campaignDefaultFormValue } =
		useCampaigns();

	const {
		resetForm,
		formState: { isValid },
		updateFormValues,
		handleSubmit,
	} = useCampaignsForm();

	useEffect(() => {
		updateFormValues(isEdit ? formData : campaignDefaultFormValue);
	}, [open]);

	const handleCloseDialog = () => {
		closeDialog();
		resetForm();
		setActiveStep(0);
	};

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleStep = (step) => {
		setActiveStep(step);
	};

	const previousStep = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const isNextStepEnabled = isValid; // Determine if next step is enabled based on form validity

	const handleCreateCampaign = (formValues) => {
		createCampaign(formValues).then(() => {
			openDialog({
				title: `Campaign has been created`,
				description: `You have successfuly craeted a campaign for ${formValues.website} (ID:${formValues._id})`,
				button: 'Close',
			});
			handleCloseDialog();
		});
	};

	const handleEditCampaign = (formValues) => {
		updateCampaign(formValues).then((response) => {
			if (response.status > 200) {
				openDialog({
					title: `Status ${response.status}`,
					description: `Form has not been updated yet, please contact admin.`,
					button: 'Close',
				});
			} else {
				openDialog({
					title: 'Campaign has been updated',
					description: `You have successfuly updated ${formValues.product_name} (ID:${formValues._id})`,
					button: 'Close',
				});
				handleCloseDialog();
			}
		});
	};

	return (
		<form>
			<Dialog
				fullScreen
				open={open}
				onClose={handleCloseDialog}
				size="large"
			>
				<DialogTitle
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<Grid container spacing={2}>
						<Grid item xs={12} md={5}>
							{isEdit ? 'Edit' : 'New'} Campaign
						</Grid>
						<Grid item xs={12} md={7}>
							<Stepper activeStep={activeStep}>
								{Steps.map((label, index) => (
									<Step
										key={index}
										completed={
											isEdit ? true : activeStep > index
										}
									>
										<StepButton
											disabled={
												isEdit
													? false
													: activeStep < index
											}
											color="inherit"
											onClick={handleStep.bind(
												this,
												index
											)}
										>
											{label}
										</StepButton>
									</Step>
								))}
							</Stepper>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent>
					<Container maxWidth="md">
						<StepperForm>
							{Steps[activeStep] === CAMPAIGN_STEPS.DETAILS && (
								<>
									<StepperTitle
										variant="h5"
										component="h2"
										sx={{ fontWeight: 'bold', mb: 2 }}
									>
										{Steps[activeStep]}
									</StepperTitle>
									<CampaignDetail />
								</>
							)}
							{Steps[activeStep] ===
								CAMPAIGN_STEPS.ENGAGEMENT && (
								<>
									<StepperTitle
										variant="h5"
										component="h2"
										sx={{ fontWeight: 'bold', mb: 2 }}
									>
										{Steps[activeStep]}
									</StepperTitle>
									<EngagementsSetting isEdit={isEdit} />
								</>
							)}
							{Steps[activeStep] === CAMPAIGN_STEPS.VIVA && (
								<>
									<StepperTitle
										variant="h5"
										component="h2"
										sx={{ fontWeight: 'bold', mb: 2 }}
									>
										{Steps[activeStep]}
									</StepperTitle>
									<VivaDetail />
								</>
							)}
							{Steps[activeStep] ===
								CAMPAIGN_STEPS.COMPETITORS && (
								<>
									<StepperTitle
										variant="h5"
										component="h2"
										sx={{ fontWeight: 'bold', mb: 2 }}
									>
										{Steps[activeStep]}
									</StepperTitle>
									<CompetitorsDetail />
								</>
							)}
						</StepperForm>
					</Container>
				</DialogContent>
				<DialogActions sx={{ padding: 3 }}>
					<Button
						variant="text"
						color="secondary"
						onClick={handleCloseDialog}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="secondary"
						disabled={activeStep === 0}
						onClick={previousStep}
					>
						Previous
					</Button>
					<Button
						type="submit"
						disabled={!isNextStepEnabled}
						variant="contained"
						color="secondary"
						onClick={
							activeStep === Steps.length - 1
								? handleSubmit(
										isEdit
											? handleEditCampaign
											: handleCreateCampaign
								  )
								: handleNext
						}
					>
						{activeStep === Steps.length - 1
							? isEdit
								? 'Update'
								: 'Create'
							: 'Next'}
					</Button>
				</DialogActions>
			</Dialog>
		</form>
	);
};

export default CampaignFormDialog;
/* eslint-enable */
