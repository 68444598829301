import React from "react";

import FormHeading from "./FormHeading";
import FormSection from "./FormSection";
import { Information } from "../Tooltip";
import FormDynamicCompetitors from "./FormDynamicCompetitors";

const CompetitorsDetail = () => {
	return (
		<>
			<FormSection>
				<FormHeading
					title="Competitor(s)"
					tooltip={
						<Information text="Enter at least one competitor for Mr. Puppet to compare your product to." />
					}
				/>

				<FormDynamicCompetitors />
			</FormSection>
		</>
	);
};

export default CompetitorsDetail;
