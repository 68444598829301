import { React, createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const DialogFormContext = createContext();

export function DialogFormContextProvider({ children }) {
	const [open, setOpen] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [formData, setFormData] = useState(null);

	const openDialog = (isEdit = false, data) => {
		setOpen(true);
		setIsEdit(isEdit);
		if (isEdit) {
			setFormData(data);
		}
	};

	const closeDialog = () => {
		setOpen(false);
		setIsEdit(false);
	};

	return (
		<DialogFormContext.Provider
			value={{
				open,
				openDialog,
				closeDialog,
				isEdit,
				setIsEdit,
				formData
			}}
		>
			{children}
		</DialogFormContext.Provider>
	);
}

DialogFormContextProvider.propTypes = {
	children: PropTypes.node
};

export function useFormDialog() {
	return useContext(DialogFormContext);
}
