import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { DateTime } from "luxon";
import { useFieldArray } from "react-hook-form";

import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useCampaignsForm } from "../../context/CampaignsFormContext";

const FormDynamicCustomEngagement = ({ currentEngagement }) => {
	const {
		formState: { errors },
		register,
		control,
		watch
	} = useCampaignsForm();

	const { fields, append, remove } = useFieldArray({
		control,
		name: "days_engagements"
	});

	const addEngagement = () => {
		let currentDate = DateTime.now().toFormat("yyyy-MM-dd");
		if (watch("days_engagements").length > 0) {
			const engagments = watch("days_engagements");
			const lastItem = engagments[engagments.length - 1];

			currentDate = DateTime.fromISO(lastItem.date)
				.plus({ day: 1 })
				.toFormat("yyyy-MM-dd");
		}

		append({
			date: currentDate,
			engagements: 0
		});
	};
	useEffect(() => {
		if (fields.length === 0) {
			addEngagement();
		}
	}, [fields.length]);

	return (
		<>
			{fields.map((field, index) => {
				return (
					<Box
						key={field.id}
						p={2}
						sx={{
							border: "1px solid #adadad",
							borderRadius: 1
						}}
						mb={2}
					>
						<Stack container spacing={2} mb={2}>
							<Stack
								direction="row"
								justifyContent="space-between"
								alignItems="center"
							>
								<Typography sx={{ fontWeight: 600 }}>
									{`Date ${index + 1}`}
								</Typography>
								<Box>
									<IconButton
										disabled={fields.length <= 1}
										onClick={() => remove(index)}
									>
										<RemoveCircleIcon
											size="small"
											color={
												fields.length <= 1
													? "disabled"
													: "warning"
											}
										/>
									</IconButton>

									<IconButton
										onClick={() => addEngagement()}
										disabled={
											currentEngagement >=
											watch("target_engagements")
										}
									>
										<AddCircleIcon
											size="small"
											color={
												currentEngagement >=
												watch("target_engagements")
													? "disabled"
													: "success"
											}
										/>
									</IconButton>
								</Box>
							</Stack>
						</Stack>
						<Grid container spacing={2} pb={2}>
							<Grid item xs={12} md={6}>
								<TextField
									{...register(
										`days_engagements[${index}].date`,
										{
											required: "Please provide a date"
										}
									)}
									defaultValue={field.date}
									label="Date"
									type="date"
									fullWidth
									error={!!errors.date}
									helperText={errors.date?.message}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<TextField
									{...register(
										`days_engagements[${index}].engagements`,
										{
											valueAsNumber: true,
											required: "Please provide a number",
											min: {
												value: 1,
												message:
													"Value must be higher than zero."
											}
										}
									)}
									defaultValue={field.engagements}
									label="Engagement"
									type="number"
									fullWidth
									error={
										!!errors.days_engagements?.[index]
											?.engagements
									}
									helperText={
										errors.days_engagements?.[index]
											?.engagements?.message
									}
								/>
							</Grid>
						</Grid>
					</Box>
				);
			})}
		</>
	);
};

FormDynamicCustomEngagement.propTypes = {
	update: PropTypes.func,
	currentEngagement: PropTypes.number
};

export default FormDynamicCustomEngagement;
