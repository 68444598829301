const sumWithAccount = (engagments) => {
	let total = 0;
	Object.entries(engagments).forEach((obj) => {
		if (obj[1].with_account) {
			total += Number(obj[1].with_account);
		}
	});

	return total;
};

export default sumWithAccount;
