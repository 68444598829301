import {useEffect} from 'react'

import { useAuth0 } from "@auth0/auth0-react";
import { useUserAuth } from "../context/UserAuthContext";

const Logout = () => {
    const { clearUserAccessToken } = useUserAuth();
    const { logout } = useAuth0();

    useEffect(() => {
        clearUserAccessToken()
        logout()
    })
    
    return null
}

export default Logout;
