import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";

import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import FormHeading from "./FormHeading";
import FormSection from "./FormSection";
import { DateTime } from "luxon";
import { Information } from "../Tooltip";
import FormDynamicCustomEngagement from "./FormDynamicCustomEngagement";
import { useCampaignsForm } from "../../context/CampaignsFormContext";

const EngagementsSetting = ({ isEdit }) => {
	const {
		formState: { errors },
		register,
		watch,
		setValue
	} = useCampaignsForm();
	const [customEngagement, setCustomEngagement] = useState(false);
	const currentTotalUsedEngagement = useMemo(() => {
		let add = watch("days_engagements")?.reduce(
			(accumulator, currentValue) => {
				return accumulator + parseInt(currentValue.engagements);
			},
			0
		);
		return add;
	}, [watch()]);

	const handleToggleCustomDays = () => {
		setCustomEngagement((prevState) => {
			if (prevState && !isEdit) {
				setValue("days_engagements", []);
			} else {
				setValue("daily_engagements", 0);
				if (!isEdit) {
					setValue("days_engagements", [
						{
							date: DateTime.now().toFormat("yyyy-MM-dd"),
							engagements: 0
						}
					]);
				}
			}
			return prevState ? false : true;
		});
	};

	useEffect(() => {
		if (isEdit && watch("days_engagements").length > 0) {
			setCustomEngagement(true);
		}
	}, []);

	return (
		<>
			<FormSection>
				<FormHeading
					title="Engagement Goals"
					tooltip={
						<Information text="When should Mr. Puppet pause the campaign?" />
					}
				/>
				<Stack
					sx={{
						border: "1px solid #adadad",
						padding: 2,
						borderRadius: 2
					}}
					direction="row"
					spacing={2}
				>
					<TextField
						{...register("target_engagements", {
							valueAsNumber: true,
							required: "Please provide a number",
							min: {
								value: 1,
								message: "Value must be higher than zero."
							}
						})}
						label="Total Campaign Engagments"
						size="small"
						type="number"
						fullWidth
						error={!!errors.target_engagements}
						helperText={errors.target_engagements?.message}
					/>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							fontSize: 12,
							fontWeight: 600
						}}
					>
						Or
					</Box>
					<TextField
						{...register("target_rank")}
						label="Target Search Rank Position"
						size="small"
						type="number"
						fullWidth
						error={!!errors.target_rank}
						helperText={errors.target_rank?.message}
					/>
				</Stack>
			</FormSection>

			<FormSection>
				<Stack
					direction="row"
					justifyContent="space-between"
					spacing={2}
				>
					<FormHeading
						title="Target Engagements Per Day"
						tooltip={
							<Information text="How many times should Mr. Puppet aim to engage with your product in a day?" />
						}
					/>
					<Box>
						<Typography variant="caption">Custom Date</Typography>
						<Switch
							checked={customEngagement}
							disabled={watch("target_engagements") < 1}
							onChange={handleToggleCustomDays}
						/>
					</Box>
				</Stack>

				<TextField
					{...register("daily_engagements", {
						valueAsNumber: true,
						min: {
							value: !customEngagement ? 1 : null,
							message: "Value must be higher than zero."
						}
					})}
					label="value"
					type="number"
					fullWidth
					disabled={customEngagement}
					error={!!errors.daily_engagements}
					helperText={errors.daily_engagements?.message}
					required={!customEngagement}
				/>
			</FormSection>

			{customEngagement ? (
				<FormSection>
					<Stack
						direction="row"
						justifyContent="space-between"
						spacing={2}
					>
						<FormHeading
							title={`Custom Engagement${
								watch("target_engagements") > 1 ? "s" : ""
							} ( ${currentTotalUsedEngagement} / ${watch(
								"target_engagements"
							)} ) `}
						/>
						{currentTotalUsedEngagement >
						watch("target_engagements") ? (
							<Typography
								variant="caption"
								sx={{
									color: (theme) => theme.palette.error.main,
									fontWeight: 600
								}}
							>
								You must deduct{" "}
								{currentTotalUsedEngagement -
									watch("target_engagements")}{" "}
								engagement!
							</Typography>
						) : null}
					</Stack>

					<FormDynamicCustomEngagement
						currentEngagement={currentTotalUsedEngagement}
					/>
				</FormSection>
			) : null}
		</>
	);
};

EngagementsSetting.propTypes = {
	isEdit: PropTypes.boolean
};
export default EngagementsSetting;
