import React from "react";

import TextField from "@mui/material/TextField";

import FormHeading from "./FormHeading";
import FormSection from "./FormSection";
import { Information } from "../Tooltip";
import { useCampaignsForm } from "../../context/CampaignsFormContext";

const VivaDetail = () => {
	const {
		formState: { errors },
		register
	} = useCampaignsForm();
	return (
		<>
			<FormSection>
				<FormHeading
					title="Product name"
					tooltip={
						<Information text="Product Name  for Viva's product for the keyword you want to rank for" />
					}
				/>

				<TextField
					{...register("product_name", {
						required: "Please provide a name"
					})}
					label="Name"
					name="product_name"
					error={!!errors.product_name}
					helperText={errors.product_name?.message}
					fullWidth
				/>
			</FormSection>

			<FormSection>
				<FormHeading
					title="Product url"
					tooltip={
						<Information text="Url link for Viva's product for the keyword you want to rank for" />
					}
				/>

				<TextField
					{...register("product_url", {
						required: "Please provide a url"
					})}
					label="Url"
					name="product_url"
					error={!!errors.product_name}
					helperText={errors.product_name?.message}
					fullWidth
				/>
			</FormSection>
		</>
	);
};

export default VivaDetail;
