import { React, createContext, useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import PropTypes from 'prop-types';

const CampaignsFormContext = createContext();

export function CampaignsFormContextProvider({ children, values }) {
	const methods = useForm({
		mode: 'onChange',
		values,
	});

	const { setValue, reset } = methods;

	// Custom function to update context values
	const updateFormValues = (values) => {
		Object.keys(values).forEach((name) => {
			setValue(name, values[name]);
			// This is a temp fix for updating form as backend accepts Int vs None
			if (name === 'first_product_search_rank' && !values[name]) {
				setValue(name, 0);
			}
		});
	};

	// Custom function to reset the form
	const resetForm = () => {
		reset(); // This will reset the form to its default values
	};

	return (
		<CampaignsFormContext.Provider
			value={{ ...methods, updateFormValues, resetForm }}
		>
			<FormProvider {...methods}>{children}</FormProvider>
		</CampaignsFormContext.Provider>
	);
}

CampaignsFormContextProvider.propTypes = {
	children: PropTypes.node,
	values: PropTypes.object,
};

export function useCampaignsForm() {
	return useContext(CampaignsFormContext);
}
