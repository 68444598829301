import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Chip as MuiChip } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

import { styled } from '@mui/system';

import sumWithAccount from '../../utils/SumEngagmentsWithAccount';

const Chip = styled(MuiChip)({
	border: 'none',
	'.MuiChip-icon': {
		fontSize: 10,
	},
});

const Status = (product) => {
	const StatusDetail = useMemo(() => {
		let output = {};

		if (!product.last_run) {
			output = {
				label: product.paused ? 'paused' : 'running',
				color: 'default',
			};
		} else if (
			(product.engagements ? sumWithAccount(product.engagements) : 0) >=
			product.target_engagements
		) {
			// Check if all engagments are used up

			output = {
				label: 'Completed',
				color: 'default',
			};
		} else if (
			product.target_rank > 0
				? product.last_product_search_rank <= product.target_rank
				: null
		) {
			// Check if last product search rank current rank is >= target rank
			output = {
				label: 'Completed',
				color: 'default',
			};
		} else if (product.paused) {
			// Check product status if both above condition hasn't been met
			output = {
				label: 'Paused',
				color: 'warning',
			};
		} else {
			output = {
				label: 'Running',
				color: 'success',
			};
		}
		return output;
	}, [product]);

	return (
		<Chip
			icon={!product.last_run ? false : <CircleIcon />}
			label={StatusDetail.label}
			color={StatusDetail.color}
			disabled={!product.last_run}
			variant="outlined"
			size="small"
		/>
	);
};

Status.propTypes = {
	product: PropTypes.object,
};

export default Status;
