import { React, useState } from "react";
import PropTypes from "prop-types";

import { TextField as MuiTextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

import { styled } from "@mui/system";
import { useGlobalAlert } from "../context/GlobalAlertDialog";
import { useUserAuth } from "../context/UserAuthContext";

const TextField = styled(MuiTextField)({
	backgroundColor: "#ffffff",
	boxShadow: ` 0px 11px 22px rgb(0 0 0 / 5%)`,
	fieldset: {
		borderColor: "rgba(0, 0, 0, 0)"
	}
});

const Searchbar = ({ sx, handleOnClick }) => {
	const [searchTerm, setSearchTerm] = useState("");
	const { userLimitedAccess } = useUserAuth();
	const { openDialog } = useGlobalAlert();
	const handleSubmit = (e) => {
		e.preventDefault();

		handleOnClick(searchTerm).then((results) => {
			if (results.length === 0) {
				openDialog({
					title: "No results found",
					description: `Search Term : ${searchTerm}`,
					button: "Close"
				});
			}
		});
	};

	return (
		<form onSubmit={handleSubmit}>
			<TextField
				fullWidth
				id="standard-bar"
				variant="outlined"
				placeholder="Search product name or url"
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.currentTarget.value)}
				sx={{
					backgroundColor: "#ffffff",
					boxShadow: ` 0px 11px 22px rgb(0 0 0 / 5%)`,
					...sx
				}}
				name="search_value"
				disabled={userLimitedAccess}
				InputProps={{
					endAdornment: (
						<IconButton onClick={handleSubmit}>
							<SearchIcon />
						</IconButton>
					)
				}}
			/>
		</form>
	);
};

Searchbar.propTypes = {
	sx: PropTypes.object,
	handleOnClick: PropTypes.func
};

export default Searchbar;
