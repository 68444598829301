import React from "react";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";

import InfoIcon from "@mui/icons-material/Info";

import { Tooltip as MuiTooltip } from "@mui/material";

export const Tooltip = () => {
	return (
		<MuiTooltip title="Add" arrow>
			<Button>Arrow</Button>
		</MuiTooltip>
	);
};

export const Information = ({ size = "small", text }) => {
	return (
		<MuiTooltip title={text} arrow>
			<InfoIcon fontSize={size} />
		</MuiTooltip>
	);
};

Information.propTypes = {
	size: PropTypes.string,
	text: PropTypes.string
};
