import React from "react"
import PropTypes from 'prop-types';

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { styled } from '@mui/system';

const StyleContainer = styled(Container)({
	textAlign:'center',
	backgroundColor: 'transparent',
	padding: 40 * 2
});

const PageTite = ({title, subtitle}) => {
    return (
        <StyleContainer maxWidth="xl">
            <Typography variant="h2" component="h1" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
                {title}
            </Typography>
            <Typography variant="h6" component="h2">
                {subtitle}
            </Typography>
        </StyleContainer>
    );
}

PageTite.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string
};

export default PageTite;
