import { React, createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import LocalStorage from "../utils/LocalStorage";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
	const [userAuthIsLoading, setUserAuthIsLoading] = useState(false);
	const [userAccessToken, setUserAccessToken] = useState("");
	const [userAuthenticated, setUserAuthenticated] = useState(null);
	const [userLimitedAccess, setUserLimitedAccess] = useState(null);

	const clearUserAccessToken = () => {
		LocalStorage.removeItem("mrpuppet_token");
		setUserAuthenticated(false);
		setUserLimitedAccess(false);
	};

	const getUserAccessToken = (auth0Token) => {
		setUserAuthIsLoading(true);
		fetch(
			`${process.env.REACT_APP_MRPUPPET_API}/authorize?token=${auth0Token}`
		)
			.then((response) => response.json())
			.then((data) => {
				setUserAccessToken(data.access_token);
				setUserAuthenticated(true);
				LocalStorage.setItem("mrpuppet_token", data.access_token);
				setUserAuthIsLoading(false);
			})
			.catch((error) => console.error(error));
	};

	return (
		<userAuthContext.Provider
			value={{
				userAuthenticated,
				getUserAccessToken,
				setUserAuthenticated,
				setUserAuthIsLoading,
				userAccessToken,
				userAuthIsLoading,
				setUserAccessToken,
				clearUserAccessToken,
				userLimitedAccess,
				setUserLimitedAccess
			}}
		>
			{children}
		</userAuthContext.Provider>
	);
}

UserAuthContextProvider.propTypes = {
	children: PropTypes.node
};

export function useUserAuth() {
	return useContext(userAuthContext);
}
