const LocalStorage = {
    setItem: (key, value) => {
      localStorage.setItem(key, JSON.stringify(value));
    },
    getItem: key => {
      const value = localStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    },
    removeItem: key => {
      const value = localStorage.removeItem(key);
      return value ? JSON.parse(value) : null;
    },
  };
  
  export default LocalStorage;